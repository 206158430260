import React, { FC } from 'react';
import * as styles from './PageStyles.module.scss';

interface PageProps {
  page: any;
}

const Page: FC<PageProps> = ({ page }) => {
  return (
    <div className={styles.page}>
      <div dangerouslySetInnerHTML={{ __html: page.html }} />
    </div>
  );
};

export default Page;
