import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Page from '../../components/Page';

interface PageProps {
  data: any;
  pageContext: {
    page: any;
  };
}

const PageTemplate: FC<PageProps> = ({ data, pageContext }) => {
  const { title, tags } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <SEO
        title={title}
        keywords={tags}
        slug={data.markdownRemark.frontmatter.slug}
        page={data.markdownRemark}
      />
      <Page page={data.markdownRemark} />
    </Layout>
  );
};

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        category
        tags
        slug
      }
    }
  }
`;

export default PageTemplate;
